<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <b-row>
                <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                        {{ $t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details') }}
                    </list-report-head>
                </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr v-if="$store.state.Auth.activeRoleId === 1">
                      <th style="width: 15%"  class="text-left">{{ $t('globalTrans.organization') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org_en }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                       <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                      <th style="width: 15%"  class="text-left">{{ $t('elearning_tpm.course_module') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.course_module_bn : formData.course_module }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-overlay :show="load">
              <b-row id="form">
                <div class="col-md-12">
                  <fieldset class="p-2 w-100">
                    <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.course_list')}}</legend>
                    <table class="table" style="width:100%">
                      <thead class="thead">
                        <tr>
                          <th style="width:40%">{{$t('elearning_tpm.course_name')}}</th>
                          <th style="width:60%">{{$t('elearning_tpm.course_details')}}</th>
                        </tr>
                      </thead>
                      <tr v-for="(course, index) in courses" :key="index">
                        <td>
                          {{ $i18n.locale === 'bn' ? course.course_name_bn : course.course_name }}
                        </td>
                        <td>
                          {{ $i18n.locale === 'bn' ? course.course_details_bn : course.course_details }}
                        </td>
                      </tr>
                    </table>
                  </fieldset>
                </div>
              </b-row>
            </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { courseWiseModule } from '../../api/routes'
export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCourseInfo(tmp.course_module_id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0
      },
      officeTypeList: [],
      courses: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      load: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCourseInfo (courseModuleId) {
      if (courseModuleId) {
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseWiseModule + '/' + this.formData.course_module_id)
        if (!result.success) {
          this.courses = []
        } else {
          this.courses = result.data
        }
        this.load = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.course_managment') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.courses)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
